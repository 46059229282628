/* text-decoration - utility
--------------------------------------------------------- */

.u-fw-n {
    font-weight: normal !important;
}

.u-fw-b {
    font-weight: bold !important;
}

.u-td-u {
    text-decoration: underline !important;
}