/**************************************

webfont.scss

***************************************/
/* ---------------------------------------------------------
webfont
----------------------------------------------------------*/
/*--  --*/

@font-face {
	font-family: 'Helvetica';
   src: url('../font/Helvetica.ttf') format('ttf'),
        url('../font/Helvetica-Bold.ttf') format('ttf'),
        url('../font/Helvetica-BoldOblique.ttf') format('ttf'),
        url('../font/Hhelvetica-light-587ebe5a59211.ttf') format('ttf'),
        url('../font/Helvetica-Oblique.ttf') format('ttf'),
        url('../font/helvetica-rounded-bold-5871d05ead8de.otf') format('otf'),
        url('../font/helvetica-compressed-5871d14b6903a.otf') format('otf');
   font-weight: normal;
   font-style: normal;
}
