/* width - utility
--------------------------------------------------------- */

.u-w-auto {
    width: auto!important;
}

.u-w-full {
    width: 100% !important;
}

.u-w-half {
    width: 50% !important;
}