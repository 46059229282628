/* display - utility
--------------------------------------------------------- */
.u-d-tb {
    display: table !important;
}

.u-d-tbc {
    display: table-cell !important;
}

.u-d-b {
    display: block !important;
}

.u-d-ib {
    display: inline-block !important;
}

.u-d-n{
    display: none !important;
}

/* ---------------------------------------------------------
breakpoint毎の表示切り替え
----------------------------------------------------------*/
.u-d-b-pc {
    display: block !important;
	@include media($notepcSize){
		display: none !important;
	}
}

.u-d-b-npc {
    display: none !important;
	@include media($notepcSize){
		display: block !important;
	}
}

.u-d-b-tb {
    display: none !important;
	@include media($tabletSize){
		display: block !important;
	}
}

.u-d-b-sp {
    display: none !important;
	@include media($mobileSize){
		display: block !important;
	}
}

.u-d-b-sps {
    display: none !important;
	@include media($mobileSize_s){
		display: block !important;
	}
}

// .u-d-n-pc {
//     display: none !important;
// 	@include media($notepcSize){
// 		display: block !important;
// 	}
// }

.u-d-n-pc {
    display: none !important;
	@include media($pcSize){
		display: block !important;
	}
}

.u-d-n-npc {
    display: block !important;
	@include media($pcSize){
		display: none !important;
	}
}

// .u-d-n-npc {
//     display: block !important;
// 	@include media($notepcSize){
// 		display: none !important;
// 	}
// }

.u-d-n-tb {
    display: block !important;
	@include media($tabletSize){
		display: none !important;
	}
}

.u-d-n-sp {
    display: block !important;
	@include media($mobileSize){
		display: none !important;
	}
}

.u-d-n-sps {
    display: block !important;
	@include media($mobileSize_s){
		display: none !important;
	}
}