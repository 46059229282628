/* vertical-align - utility
--------------------------------------------------------- */

.u-va-t {
    vertical-align: top !important;
}

.u-va-m {
    vertical-align: middle !important;
}

.u-va-b {
    vertical-align: bottom !important;
}