/**************************************

recruit.scss

***************************************/

.recruit {
    // mainVisual
    .mainVisual {
    }
    // mainNav
    .mainNav {
        .c-mainNavTeams {
            max-width: 600px;
            width: wCon(600,1600);
            @include media($mobileSize){
                max-width: 600px;
                width: wCon(600,640);
                @include wrap();
            }    
            .c-mainNavTeam {
                width: wCon(225,600);
                box-sizing: border-box;
                @include media($mobileSize){
                    width: wCon(298,600);
                    &:nth-of-type(1) {
                        margin-bottom: 10px;
                    }
                    &:nth-of-type(2) {
                        margin-bottom: 10px;
                        border-right: 1px solid $white;
                    }
                }
                a {
                    padding: wCon(40,600) wCon(50,600);
                    box-sizing: border-box;
                }
            }
        }
    }
    // adopt
    .adopt {
        background-image: url(../img/recruit/recruit_pic2.jpg);
        background-position: 50% 100%;
        background-repeat: no-repeat;
        padding: wCon(50,1600) 0 wCon(205,1600);
        background-color: $black;
        @include media($mobileSize){
            padding: wCon(50,640) 0 wCon(205,640);
        }
        .adopt__outer {
            width: 100%;
            &::before {
                content: "";
                height: 126px;
                margin-top: -126px;
                display: block;
                visibility: hidden;
                @include media($mobileSize){
                    height: 87px;
                    margin-top: -87px;
                }
            }
            .adopt__inner {
                max-width: 1040px;
                width: wCon(1040,1600);
                margin: 0 auto;
                background-color: $white;
                padding: wCon(50,1600) wCon(20,1600) wCon(60,1600);
                box-sizing: border-box;
                @include media($mobileSize){
                    width: wCon(600,640);
                    padding: wCon(50,640) wCon(20,640) wCon(60,640);
                }
                // message
                .adopt__messageTeams {
                    @include flex();
                    @include justify();
                    @include media($mobileSize){
                        @include column-reverse();
                    }
                    .adopt__messageTeam {
                        &:first-child {
                            width: wCon(510,1040);
                            @include media($mobileSize){
                                width: 100%;
                            }
                            .adopt__messageSign {
                                text-align: right;
                            }
                            .adopt__messageTit {
                                margin-bottom: 20px;
                            }
                            .adopt__messageTxt {
                                color: #333;
                                @include fontsize(14);
                                line-height: 1.8;
                                margin-bottom: 20px;
                            }
                        }
                        &:last-child {
                            width: wCon(464,1040);
                            @include media($mobileSize){
                                width: 100%;
                                margin-bottom: 30px;
                            }
                        }
                    }
                }
                // work
                .adopt__workDetail {
                    .c-titLength {
                        margin-bottom: 20px;
                    }
                    .adopt__workDetailTxt {
                        @include fontsize(14);
                        line-height: 1.8;
                        color: #333;
                    }
                }
                // meat 
                .adopt__meatTable {
                    .adopt__meatList {
                        width: 100%;
                        display: table;
                        box-sizing: border-box;
                        border-top: 1px solid #ADADAD;
                        border-left: 1px solid #ADADAD;
                        border-right: 1px solid #ADADAD;
                        @include media($mobileSize){
                            border-top: none;
                            &:first-child {
                                border-top: 1px solid #ADADAD;
                            }    
                        }
                        &:last-child {
                            border-bottom: 1px solid #ADADAD;
                        }
                        .adopt__meatTit {
                            width: wCon(200,1040);
                            display: table-cell;
                            box-sizing: border-box;
                            padding-top: wCon(15,1040);
                            padding-bottom: wCon(15,1040);
                            padding-left: wCon(15,1040);
                            border-right: 1px solid #ADADAD;
                            @include fontsize(14);
                            background-color: #980106;
                            color: $white;
                            @include media($mobileSize){
                                width: 100%;
                                display: block;
                                border-right: none;
                                padding-top: wCon(30,640);
                                padding-bottom: wCon(30,640);
                                padding-left: wCon(30,640);    
                            }
                        }
                        .adopt__meatDetail {
                            display: table-cell;
                            width: wCon(800,1040);
                            box-sizing: border-box;
                            padding-top: wCon(15,1040);
                            padding-bottom: wCon(15,1040);
                            padding-left: wCon(15,1040);
                            @include fontsize(14);
                            color: #333;
                            line-height: 1.4;
                            @include media($mobileSize){
                                width: 100%;
                                display: block;
                                padding-top: wCon(30,640);
                                padding-bottom: wCon(30,640);
                                padding-left: wCon(30,640);    
                            }
                            a {
                                color: #980106;
                                @include fontsize(14);
                                text-decoration-line: underline;
                                transition: .5s;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/*--  --*/
