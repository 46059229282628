/**************************************

component.scss

***************************************/
/* ---------------------------------------------------------
テキスト装飾
----------------------------------------------------------*/


/*---- ボタンアニメーション ----*/ 
// ライン
.c-line {
    position: relative;
    display: inline-block;
    font-size: 1em;
    text-decoration-line: none;
    &::before {
        position: absolute;
        top: 1.8em;
        left: 0;
        content: "";
        display: inline-block;
        width: 0;
        height: 1px;
        background-color: $red;
        transition: 0.2s;
    }
    &:hover {
        &::before {
            width: 100%;
        }
    }
}

//ボタンアニメーション
.c-button {
  position: relative;
  z-index: 2;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all .3s;
  transition: all .3s;
  &:hover {
    &::after {
        top: 0;
        left: 0;
      }
  }
  &::after {
    top: -100%;
    left: -100%;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    display: block;
    content: '';
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .3s;
    transition: all .3s;
  }
}

/*-- 見出し1 --*/
.c-tit {
    .c-titEn {
        color: $white;
        font-weight: bold;
        @include fontsize(50);
        font-family: $fontFamilyHelvetica;
        margin-bottom: 8px;
        letter-spacing: .1em;
        @include media($mobileSize){
            @include fontsize(46);
        }    
    }
    .c-titJa {
        @include fontsize(14);
        color: $red;
        @include media($mobileSize){
            @include fontsize(16);
        }    
    }
}

/*-- 見出し2 --*/
.c-titLong {
    position: relative;
    margin-bottom: 45px;
    @include media($mobileSize){
        margin-bottom: 35px;
    }
    &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: -20px;
        background-color: #830106;
        width: 110px;
        height: 2px;
        z-index: 1;
        @include media($mobileSize){
            bottom: -10px;
        }
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: -20px;
        background-color: #808080;
        height: 2px;
        width: 100%;
        @include media($mobileSize){
            bottom: -10px;
        }
    }
    .c-titLongJa {
        @include fontsize(34);
        color: $black;
        font-weight: bold;
        display: inline-block;
        margin-right: 10px;
        line-height: 1.2;
        @include media($mobileSize){
            @include fontsize(28);
        }    
    }
    .c-titLongEn {
        @include fontsize(14);
        color: $red;
        font-family: $myriad;
        font-weight: 600;
        font-style: normal;
    }
}

/*-- 見出し3 --*/
.c-titLength {
    @include fontsize(26);
    font-weight: bold;
    position: relative;
    padding-left: 25px;
    line-height: 1.2;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 10px;
        height: 25px;
        background-color: #B80000;
        line-height: 1.2;
    }
}

/*-- 共通のレイアウト --*/

// 下層ページのmainvisual
.c-mainVisualUnderStyle {
    width: 100%;
    position: relative;
    img {
        width: 100%;
        @include media($mobileSize){
            object-fit: cover;
            height: 350px;
        }
    }
    .c-mainVisualUnderStyle__inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        @include content();
        @include flex();
        @include column();
        text-align: center;
        @include media($mobileSize){
            top: 50px;
        }
        .c-tit {
            .c-titJa {
                color: #fff;
            }
        }
    }
    .c-panLists {
        position: absolute;
        // top: 0;
        left: 0;
        right: 0;
        bottom: 5%;
        margin: 0 auto;
        @include flex();
        text-align: center;
        width: wCon(1040,1600);
        max-width: 1040px;
        @include media($mobileSize){
            width: wCon(600,640);
        }
        .c-panList {
            color: #C2C2C2;
            @include fontsize(11);
            position: relative;
            &:first-child {
                &::after {
                    content: ">";
                    display: inline-block;
                    color:  #C2C2C2;
                    margin-right: 5px;
                }
            }
            a {
                color: #C2C2C2;
                @include fontsize(11);
            }
            .c-line {
                &::before {
                    top: 1.2em;
                    background-color: #C2C2C2;
                }
            }
            &:nth-of-type(3)  {
                margin-left: 10px;
                &::after {
                    top: 0;
                    left: -7px;
                    position: absolute;
                    content: ">";
                    color:  #C2C2C2;
                    margin-right: 5px;
                }
            }
        }
    }
}
// mainNav
.c-mainNav {
    padding: wCon(10,1600) 0;
    background-color: #AE0106;
    @include media($mobileSize){
        padding: 5px 0;
    }
    .c-mainNavTeams {
        @include flex();
        margin: 0 auto;
        .c-mainNavTeam {
            border-left: 1px solid $white;
            &:last-child {
                border-right: 1px solid $white;
            }
            a {
                width: 100%;
                display: block;
                color: $white;
                box-sizing: border-box;
                @include fontsize(14);
                text-align: center;
                position: relative;
                @include media($pcSize){
                    @include fontsize(12);
                }
                @include media($mobileSize){
                    @include fontsize(16);
                }
                &:hover {
                    @include opa();
                }
                &::after {
                    content: "";
                    top: 42%;
                    right: 8%;
                    position: absolute;
                    background-image: url(../img/common/arrow_under_page.svg);
                    background-repeat: no-repeat;
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
}
// 下層ページのテキスト
.c-underPage {
    text-align: center;
    @include fontsize(14);
    color: $white;
    line-height: 2;
    @include media($mobileSize){
        width: wCon(600,640);
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
}

/*-- リード文--*/



