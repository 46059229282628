/* text-align - utility
--------------------------------------------------------- */

.u-ta-l {
    text-align: left !important;
}

.u-ta-c {
    text-align: center !important;
}

.u-ta-r {
    text-align: right !important;
}