/**************************************

contact.scss

***************************************/

.contact {
    // 初期設定
    input[type="submit"],
    input[type="button"],
    input[type="reset"] {
        -webkit-appearance: none;
        border-radius: 0;  
        background: none; 
        border: 0; 
        box-sizing: border-box;
    }
    // mainVisual
    .mainVisual {
    }
    // mainNav
    // .mainNav {
    //     .c-mainNavTeams {
    //         max-width: 450px;
    //         width: wCon(450,1600);
    //         @include media($mobileSize){
    //             max-width: 600px;
    //             width: wCon(600,640);
    //         }    
    //         .c-mainNavTeam {
    //             width: wCon(180,450);
    //             box-sizing: border-box;
    //             a {
    //                 padding: wCon(40,450) wCon(50,450);
    //                 box-sizing: border-box;
    //             }
    //         }
    //     }
    // }

    // foam
    .foam {
        background-image: url(../img/contact/contact_pic2.jpg);
        background-position: 0% 30%;
        background-repeat: no-repeat;
        padding: wCon(50,1600) 0 wCon(205,1600);
        background-color: $black;
        @include media($mobileSize){
            padding: wCon(50,640) 0 wCon(205,640);
        }
        .c-underPage {
            a {
                color: #9C0106;
                text-decoration-line: underline;
                &:hover {
                    text-decoration-line: none;
                }
            }
        }
        .foam__outer {
            width: 100%;
            &::before {
                content: "";
                height: 126px;
                margin-top: -126px;
                display: block;
                visibility: hidden;
                @include media($mobileSize){
                    height: 87px;
                    margin-top: -87px;
                }
            }
            .foam__inner {
                max-width: 1040px;
                width: wCon(1040,1600);
                margin: 0 auto;
                background-color: $white;
                padding: wCon(50,1600) wCon(20,1600) wCon(60,1600);
                box-sizing: border-box;
                @include media($mobileSize){
                    width: wCon(600,640);
                    padding: wCon(50,640) wCon(20,640) wCon(60,640);
                }
                .contactForm__table {
                    width: 100%;
                    .contactForm__tableInner {
                        border-bottom: 1px solid #F1F1F1;
                        width: 100%;
                        &:first-child {
                            border-top: 1px solid #F1F1F1;
                        }
                        th {
                            @include fontsize(16);
                            width: wCon(220,1040);
                            text-align: left;
                            font-weight: bold;
                            padding-top: wCon(30,1040);
                            padding-left: wCon(30,1040);
                            padding-bottom: wCon(30,1040);
                            vertical-align: top;
                            box-sizing: border-box;
                            @include media($pcSize){
                                @include fontsize(14);
                            }
                            @include media($mobileSize){
                                width: 100%;
                                display: block;
                                @include fontsize(16);
                                padding-top: wCon(30,640);
                                padding-bottom: wCon(30,640);    
                            }
                            .contactForm__caution {
                                @include fontsize(12);
                                background-color: #9C0106;
                                color: $white;
                                padding: 3px 3px;
                                box-sizing: border-box;
                                margin-right: 10px;
                            }
                            .contactForm__any {
                                @include fontsize(12);
                                background-color: #CCCCCC;
                                color: #666666;
                                padding: 3px 3px;
                                box-sizing: border-box;
                                margin-right: 10px;
                            }
                        }
                        td {
                            width: wCon(564,1040);
                            padding-top: wCon(30,1040);
                            padding-bottom: wCon(30,1040);
                            padding-left: wCon(30,1040);
                            box-sizing: border-box;
                            @include media($mobileSize){
                                width: 100%;
                                display: block;
                                padding-top: wCon(30,640);
                                padding-bottom: wCon(30,640);
                            }
                            .contactForm__inputText {
                                width: wCon(755,1040);
                                padding: 10px 0 10px 10px;
                                box-sizing: border-box;
                                background-color: #F2F2F2;
                                border: none;
                                @include fontsize(12);
                                @include media($mobileSize){
                                    width: 100%;
                                }    
                            }
                            .contactForm__inputShort {
                                width: wCon(208,1040);
                                @include media($mobileSize){
                                    width: 50%;
                                }    
                            }
                            .text_small {
                                @include fontsize(12);
                                line-height: 1.5;
                            }
                            .error {
                                @include fontsize(12);
                                color: #9C0106;
                                margin-top: 10px;
                            }
                            select {
                                background-color: #F2F2F2;
                                border: none;
                                padding: 8px 0 8px 5px;
                                box-sizing: border-box;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                border-radius: 0;  
                                border: 0; 
                                box-sizing: border-box;
                                @include fontsize(12); 
                                width: 45%;        
                                background-image: url(../img/contact/contact_pic3.svg);
                                background-position: 95% 50%;
                                background-repeat: no-repeat;
                                @include media($mobileSize){
                                    width: 70%;
                                }           
                            }
                        }
                    }    
                }
                .contactForm__Txt {
                    @include fontsize(16);
                    color: #000;
                    text-align: center;
                    line-height: 1.5;
                    a {
                        color: #9C0106;
                        text-decoration-line: underline;
                        &:hover {
                            text-decoration-line: none;
                        }
                    }
                }
                .contactForm__protectionCheckbox {
                    text-align: center;
                    // .contactForm__protectionCheckboxInner {
                    //     box-sizing: border-box;
                    //     -webkit-appearance: none;
                    //     -moz-appearance: none;
                    //     appearance: none;
                    //     border: 2px solid #000;
                    //     width: 22px;
                    //     height: 22px;
                    //     vertical-align: middle;
                    //     @include fontsize(16);
                    //     color: #000;    
                    // }
                }
                .contactForm__submit {
                    width: wCon(812,1040);
                    text-align: center;
                    margin: 0 auto;
                    .contactForm__submitBtn {
                        width: 100%;
                        display: block;
                        padding: 20px 0;
                        box-sizing: border-box;
                        color: $white;
                        @include fontsize(16);
                        cursor: pointer;
                        background-color: #9C0106;
                        border: 1px solid #9C0106;
                        transition: .5s;
                        &:hover {
                            color: #9C0106;
                            background-color: $white;    
                        }
                    }
                }
                .contactForm__return {
                    margin: 30px auto;
                    width: wCon(812,1040);
                    text-align: center;
                    .contactForm__returnBtn {
                        width: 100%;
                        display: block;
                        padding: 20px 0;
                        box-sizing: border-box;
                        color: $white;
                        @include fontsize(16);
                        cursor: pointer;
                        background-color: #333;
                        transition: .5s;
                        border: 1px solid #333;
                        &:hover {
                            color: #333;
                            background-color: $white;    
                        }
                    }
                }
                .contactForm__finishTxt {
                    text-align: center;
                    @include fontsize(16);
                    line-height: 1.5;
                }
                .contactForm__finishBtn {
                    text-align: center;
                    a {
                        @include fontsize(14);
                        &::before {
                            top: 1.2em;
                        }
                    }
                }
            }
        }
    }
}


/*--  --*/
