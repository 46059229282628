/**************************************

products.scss

***************************************/

.products {
    // mainVisual
    .mainVisual {
    }
    // mainNav
    .mainNav {
        .c-mainNavTeams {
            max-width: 450px;
            width: wCon(450,1600);
            @include media($mobileSize){
                max-width: 600px;
                width: wCon(600,640);
            }    
            .c-mainNavTeam {
                width: wCon(180,450);
                box-sizing: border-box;
                a {
                    padding: wCon(40,450) wCon(50,450);
                    box-sizing: border-box;
                }
            }
        }
    }
    // parts
    .parts {
        background-image: url(../img/products/products_pic37.jpg);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        padding: wCon(50,1600) 0 wCon(205,1600);
        background-color: $black;
        @include media($mobileSize){
            padding: wCon(50,640) 0 wCon(205,640);
        }
        .parts__outer {
            width: 100%;
            &::before {
                content: "";
                height: 126px;
                margin-top: -126px;
                display: block;
                visibility: hidden;
                @include media($mobileSize){
                    height: 87px;
                    margin-top: -87px;
                }
            }
            .parts__inner {
                max-width: 1040px;
                width: wCon(1040,1600);
                margin: 0 auto;
                background-color: $white;
                padding: wCon(50,1600) wCon(20,1600) wCon(60,1600);
                box-sizing: border-box;
                @include media($mobileSize){
                    width: wCon(600,640);
                    padding: wCon(50,640) wCon(20,640) wCon(60,640);
                }
                .parts__teams {
                    @include flex();
                    @include wrap();
                    .parts__team {
                        width: wCon(248.7,1040);
                        margin-right: wCon(15,1040);
                        margin-bottom: 35px;
                        @include media($mobileSize){
                            width: wCon(292,600);
                            margin-right: wCon(15,600);
                            margin-bottom: 20px;
                            &:nth-child(2n) {
                                margin-right: 0;
                            }
                        }
                        &:nth-child(4n) {
                            margin-right: 0;
                        }
                        &:nth-child(9) {
                            margin-bottom: 0;
                            @include media($mobileSize){
                                margin-bottom: 20px;
                            }
                        }
                        &:nth-child(10) {
                            margin-bottom: 0;
                            @include media($mobileSize){
                                margin-bottom: 20px;
                            }
                        }
                        &:nth-child(11) {
                            margin-bottom: 0;
                        }
                        &:nth-child(12) {
                            margin-bottom: 0;
                        }
                        .parts__list {
                            .parts__img {
                                margin-bottom: 10px;
                                img {
                                    width: 100%;
                                }
                            }
                            .parts__txt {
                                @include fontsize(14);
                                line-height: 1.2;
                                color: #333;
                            }
                        }
                    }
                }      
            }
        }
    }
}


/*--  --*/
