/**************************************

factory.scss

***************************************/

.factory {
        // mainVisual
        .mainVisual {
        }
        // mainNav
        .mainNav {
            .c-mainNavTeams {
                max-width: 450px;
                width: wCon(450,1600);
                @include media($mobileSize){
                    max-width: 600px;
                    width: wCon(600,640);
                }    
                .c-mainNavTeam {
                    width: wCon(225,450);
                    box-sizing: border-box;
                    a {
                        padding: wCon(20,450) wCon(50,450);
                        box-sizing: border-box;
                    }
                }
            }
        }
        // equipment
        .equipment {
            background-image: url(../img/factory/factory_pic2.jpg);
            background-position: 100% 18%;
            background-repeat: no-repeat;
            padding: wCon(50,1600) 0 wCon(205,1600);
            background-color: $black;
            @include media($mobileSize){
                padding: wCon(50,640) 0 wCon(205,640);
            }
            .equipment__outer {
                width: 100%;
                &::before {
                    content: "";
                    height: 126px;
                    margin-top: -126px;
                    display: block;
                    visibility: hidden;
                    @include media($mobileSize){
                        height: 87px;
                        margin-top: -87px;
                    }
                }    
                .equipment__inner {
                    max-width: 1040px;
                    width: wCon(1040,1600);
                    margin: 0 auto;
                    background-color: $white;
                    padding: wCon(50,1600) wCon(20,1600) wCon(60,1600);
                    box-sizing: border-box;
                    @include media($mobileSize){
                        width: wCon(600,640);
                        padding: wCon(50,640) wCon(20,640) wCon(60,640);
                    }
                    .equipment__teams {
                        @include flex();
                        @include wrap();
                        .equipment__team {
                            width: wCon(332,1040);
                            margin-right: wCon(22,1040);
                            margin-bottom: 50px;
                            @include media($mobileSize){
                                width: wCon(293,600);
                                margin-bottom: 30px;
                                &:nth-child(2n) {
                                    margin-right: 0;
                                }
                                &:nth-child(odd) {
                                    margin-right: wCon(22,1040)!important;
                                }
                            }
                            &:nth-child(3n) {
                                margin-right: 0;
                            }
                            &:nth-of-type(10) {
                                margin-bottom: 0;
                            }
                            &:nth-of-type(11) {
                                margin-bottom: 0;
                            }     
                            &:nth-of-type(12) {
                                margin-bottom: 0;
                            }       
                            .equipment__list {
                                .equipment__img {
                                    margin-bottom: 20px;
                                    img {
                                        width: 100%;
                                    }
                                }
                                .equipment__txt {
                                    @include fontsize(14);
                                    line-height: 1.2;  
                                    color: #333;  
                                }
                            }
                        }
                    } 
                    .equipment__point {
                        @include fontsize(14);
                        margin-bottom: 20px;
                    }
                    .equipment__table {
                        @include media($mobileSize){
                            overflow: auto;
                            white-space: nowrap;
                        }
                        .equipment__tableInner {
                            border-collapse: collapse;
                            width: 100%;
                            tr {
                                background-color: #F1F1F1;
                                th {
                                    background-color: #9C0106;
                                    color: $white;
                                    border: 1px solid #B2B2B2;
                                    box-sizing: border-box;
                                    padding: 20px 0;
                                    @include fontsize(14);
                                }
                                td {
                                    @include fontsize(12);
                                    padding: 10px 20px;
                                    box-sizing: border-box;
                                    border: 1px solid #B2B2B2;
                                    text-align: left;
                                    line-height: 1.2;
                                    vertical-align: top;
                                    &:nth-of-type(2) {
                                        text-align: center;
                                    }
                                }
                                &:nth-of-type(even) {
                                    td {
                                       background-color: $white;
                                    }
                                }
                                .equipment__tableTd--center {
                                    text-align: center;
                                }
                            }
                        }
                    }  
                    .equipment__table::-webkit-scrollbar {
                        height: 5px;
                    }
                    .equipment__table::-webkit-scrollbar-track {
                        background-color: #B2B2B2;
                    }
                    .equipment__table::-webkit-scrollbar-thumb  {
                        background-color: #9C0106;
                    }
                }
            }
        }    
}


/*--  --*/
