/**************************************

top.scss

***************************************/

.top  {
    img {
        vertical-align: middle;
    }
    // mainVisual
    .mainVisual {
        width: 100%;
        position: relative;
        .mainVisual__teams {
            .mainVisual__team {
                width: 100%;
                height: 100vh;
                position: relative;
                background-repeat: no-repeat;
                background-position: 50% 100%;
                background-size: cover;
            }
            .mainVisual__team--pic1 {
                background-image: url(../img/top/slider_pic1_pc.jpg);
                @include media($mobileSize){
                    background-image: url(../img/top/slider_pic3_sp.jpg);
                    background-position: 50% 50%;
                }
            }
            .mainVisual__team--pic2 {
                background-image: url(../img/top/slider_pic2_pc.jpg);
                @include media($mobileSize){
                    background-image: url(../img/top/slider_pic2_sp.jpg);
                    background-position: 50% 50%;
                }
            }
            .mainVisual__team--pic3 {
                background-image: url(../img/top/slider_pic3_pc.jpg);
                @include media($mobileSize){
                    background-image: url(../img/top/slider_pic1_sp.jpg);
                    background-position: 50% 50%;
                }
            }
        }
        .mainVisual__copyOuter {
            position: absolute;
            width: 100%;
            @include translateY(0%);
            // top: 0;
            left: 0;
            bottom: 120px;
            right: 0;
            margin: 0 auto;
            @include media($mobileSize){
                bottom: 0;
                top: 150px;
            }
            .mainVisual__heart {
                width: 100%;
                // position: absolute;
                // @include translateY(0%);
                // top: 0;
                // left: 0;
                // bottom: 100px;
                // right: 0;
                // margin: 0 auto;
                @include media($mobileSize){
                    width: 95%;
                    margin: 0 auto;
                }
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .mainVisual__copy {
                // position: absolute;
                // @include translateY(0%);
                // top: 27%;
                // left: 0;
                // bottom: 50%;
                // right: 0;
                margin: 0 0 -37px auto;
                width: wCon(1320,1600);
                @include fontsize(70);
                line-height: 1.3;
                color: $white;
                font-weight: bolder;
                font-family: $fontFamilySub;
                font-style: italic;
                @include media($mobileSize){
                    @include fontsize(48);
                    width: wCon(620,640);
                    margin: 0 0 -30px auto;
                }
                @include media($mobileSize_s){
                    @include fontsize(42);
                }
                // @media screen and(max-height: 500px) {
                    
                //  }
            }    
        }
        .mainVisual__appendDots {
            position: absolute;
            bottom: 12%;
            width: 82.5%;
            padding: 0;
            margin: 0 0 0 auto;
            list-style: none;
            text-align: left;
            left: 0;
            right: 0; 
            @include flex();
            @include flex-end();
            @include flex-direction();
            align-items: flex-end;
            @include media($mobileSize){
                @include column();
                width: wCon(620,640);
                margin: 0 auto;
                bottom: 14%;
            }
            @include media($mobileSize_s){
                @media screen and (-webkit-min-device-pixel-ratio:0){
                    bottom: 23%;
                }
                @media screen and (-webkit-min-device-pixel-ratio:0) {
                    _::-webkit-full-page-media, _:future, :root body {
                        bottom: 30%;
                    }
                }
                @-moz-document url-prefix(){
                    bottom: 23%;
                }
            }
            .mainVisual__appendSentence {
                margin-left: 17%;
                @include media($mobileSize){
                    margin-left: 0;
                    text-align: center;
                    width: 100%;
                }
                .mainVisual__appendCopy {
                    text-align: center;
                    @include fontsize(17);
                    line-height: 1.8;
                    color: $white;
                    font-family: $fontFamilyYuGothic;
                    font-style: italic;
                    letter-spacing: .1em;
                    margin-bottom: 35px;
                    @include media($mobileSize){
                        @include fontsize(16);
                    }
                }    
                .mainVisual__appendTxt {
                    color: $white;
                    @include fontsize(14);
                    font-style: italic;
                    font-weight: normal;
                    letter-spacing: .15em;
                    @include media($mobileSize){
                        margin-bottom: 40px;
                    }
                }    
            }
            .mainVisual__appendDown {
                // @include media($mobileSize){
                //     margin-bottom: -12px;            
                // }
            }
            .slick-dots {
                @include media($mobileSize){
                    margin-right: auto;
                }
            }
        }
        .mainVisual__news {
            width: 100%;
            background-color: $black;
            padding: 15px 0;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            box-sizing: border-box;
            .mainVisual__newsIneer {
                width: wCon(1300,1600);
                max-width: 1300px;
                padding: 0;
                margin-left: auto;
                margin-right: 1%;
                @include flex();
                @include justify();
                align-items: center;
                @include media($mobileSize){
                    width: wCon(620,640);
                    margin: 0 auto;
                    align-items: normal;
                }
                @include media($mobileSize_s){
                    @include column();
                }
                .mainVisual__newsDetail {
                    color: $white;
                    @include flex();
                    @include justify();
                    @include fontsize(14);
                    align-items: center;
                    line-height: 1.2;
                    @include media($mobileSize){
                        @include fontsize(12);
                    }
                    @include media($mobileSize_s){
                        align-items: baseline;
                    }
                    .mainVisual__newsDetailTxt {
                        @include media($mobileSize_s){
                            margin-right: auto;
                            margin-bottom: 10px;
                        }
                        a {
                            color: $white;
                            @include fontsize(14);
                            line-height: 1.5;
                            @include media($mobileSize){
                                @include fontsize(12);
                            }        
                            &:hover {
                                color: $red;
                                transition: .5s;
                            }
      
                        }
                    }
                }
                .mainVisual__newsBtn {
                    margin-left: auto;
                    margin-right: 80px;
                    @include media($mobileSize){
                        margin-left: 0;
                        margin-right: 0;
                    }
                    @include media($mobileSize_s){
                        width: 170px;
                        margin: 0 auto;
                    }
                    a {
                        color: $white;
                        @include fontsize(14);
                        border: 1px solid $white;
                        padding: 5px 13px 5px 25px;                    
                        position: relative;
                        width: 100%;
                        display: block;
                        box-sizing: border-box;
                        @include media($mobileSize){
                            @include fontsize(12);
                            padding: 7px 13px 5px 25px;
                        }
                        @include media($mobileSize_s){
                            text-align: center;
                            padding: 10px 13px 10px 25px;
                        }
                        &::before {
                            content: "";
                            width: 10px;
                            height: 10px;
                            background-image: url(../img/top/top_news_off_pic4.svg);
                            background-repeat: no-repeat;
                            background-size: cover; 
                            position: absolute;
                            left: 7px;
                            top: 7px; 
                            @include media($mobileSize_s){
                                top: 10px; 
                            }     
                        }
                        &:hover {
                            color: $red;
                            border: 1px solid $red;
                            transition: .5s;
                            &::before {
                                background-image: url(../img/top/top_news_on_pic5.svg);
                            }
                        }
                    }
                }
                .mainVisual__newsDown {
                    a {
    
                    }
                }
            }
        }    
    }
    // profile
    .profile {
        width: 100%;
        position: relative;
        img {
            width: 100%;
            @include media($mobileSize){
                object-fit: cover;
                height: 340px;
            }
        }
        .profile__tit {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
            width: wCon(810,1600);
            max-width: 810px;
            @include flex();
            @include content();
            @include column();
            text-align: center;
            @include media($mobileSize){
                width: wCon(600,640);
            }
            .c-tit {
                margin-bottom:  wCon(35,574);
            }
            .profile__copy {
                color: $white;
                @include fontsize(14);
                line-height: 1.8;
                margin-bottom:  wCon(40,574);
            }
            .profile__btn {
                width: 170px;
                margin: 0 auto;
                a {
                    width: 100%;
                    display: block;
                    color: $white;
                    @include fontsize(12);
                    border: 1px solid $white;
                    padding: 10px 0;
                    background-color: rgba(0,0,0,0.5);
                    &:hover {
                        &::after {
                          background-color: $black;
                        }                                
                    }
                }
            }
        }
    }
    // business
    .business {
        background-color: $black;
        .business__titOuter {
            // background-color: $black;
            padding: wCon(50,1600) 0 wCon(55,1600);
            box-sizing: border-box;
            @include media($mobileSize){
                padding: wCon(65,640) 0 wCon(70,640);
            }
            .business__titInner {
                @include flex();
                @include justify();
                width: wCon(1000,1600);
                max-width: 1000px;
                margin: 0 auto;
                @include media($mobileSize){
                    width: wCon(600,640);
                    @include column();
                }    
                .c-tit {
                width: wCon(500,1000);
                @include media($mobileSize){
                        width: 100%;
                        margin-bottom: 20px;
                    }
                }
                .business__copy {
                    color: $white;
                    @include fontsize(14);
                    line-height: 1.8;
                    width: wCon(720,1000);
                    @include media($mobileSize){
                        width: 100%;
                    }
                }
            }    
        }
        .business__firstMap {
            .area {
                width: 100%;
                display: block;
                position: relative;
                cursor: pointer;
            }
            img[usemap] {
                max-width: 100%;
                height: auto;
                &:hover {
                    background-color: rgba(0,0,0,0.3);
                    -webkit-transition: all .5s;
                    transition: all .5s;    
                }
            }    
        }
        .business__firstTeams {
            width: 100%;
            @include flex();
            @include wrap();
            @include media($mobileSize){
                @include column();
            } 
            .business__firstTeam {
                width: wCon(800,1600);
                @include media($mobileSize){
                    width: 100%;
                }
                a {
                    width: 100%;
                    position: relative;
                    display: block;
                    &:hover {
                        &::after {
                            background-color: rgba(0,0,0,0.3);
                            -webkit-transition: all .5s;
                            transition: all .5s;    
                          }
                      }                    
                    &::after {
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        position: absolute;
                        display: block;
                        content: '';
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        -webkit-transition: all .5s;
                        transition: all .5s;
                    }                     
                    .c-tit {
                        position: absolute;
                        left: 10%;
                        right: 0;
                        bottom: 10%;
                        margin: 0 auto;
                        @include flex();
                        @include column();
                        z-index: 2;
                        @include media($mobileSize){
                            left: 5%;
                        }
                        .c-titEn {
                            @include fontsize(22);
                        }
                        .c-titJa {
                            @include fontsize(14);
                        }
                    }
                    img {
                        width: 100%;
                    }    
                }
                &:nth-child(even) {
                    margin-right: 0;
                }
            }
        }
        .business__secondTeams {
            width: 100%;
            @include flex();
            @include media($mobileSize){
                @include column();
            } 
            .business__secondTeam {
                width: wCon(552,1600);
                @include media($mobileSize){
                    width: 100%;
                }     
                a {
                    width: 100%;
                    position: relative;
                    display: block;
                    &:hover {
                        &::after {
                            background-color: rgba(0,0,0,0.3);
                            -webkit-transition: all .5s;
                            transition: all .5s;    
                          }
                      }                    
                    &::after {
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        position: absolute;
                        display: block;
                        content: '';
                        -webkit-box-sizing: border-box;
                        -moz-box-sizing: border-box;
                        box-sizing: border-box;
                        -webkit-transition: all .5s;
                        transition: all .5s;
                    }                   
                    .c-tit {
                        position: absolute;
                        left: 5%;
                        right: 0;
                        bottom: 10%;
                        margin: 0 auto;
                        @include flex();
                        @include column();
                        z-index: 2;
                        .c-titEn {
                            @include fontsize(22);
                        }
                        .c-titJa {
                            @include fontsize(14);
                        }
                    }
                    img {
                        width: 100%;
                    }    
                }
                &:nth-child(even) {
                    margin-right: 0;
                }
            }
        }
        // hoverアニメーション 
        .business__firstMap {
            position:relative;
            background-image: url(../img/top/top_pic21.jpg);
            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: left top;
            background-size:contain;
            max-width: 1450px;
            width: 100%;
            margin: 0 auto;
        }
        .business__firstMap > img[usemap] {
            position:relative;
            z-index:4;
        }
        .business_image_hover {
            position:absolute;
            top:0;
            left:-15px;
            max-width:100%;
            height:auto;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            z-index:0;
            opacity:0;
        }
        .business_image_hover.motion {
            left:0;
            opacity:1;
        }        
    }
    // productsMachinery 
    .productsMachinery {
        width: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover;
        background-image: url(../img/top/top_pic15.jpg);
        background-repeat: no-repeat;
        padding: wCon(90,1600) 0;
        @include media($mobileSize){
            background-position: 10% 10%;
            padding: wCon(90,640) 0;
        }
        .productsMachiner__inner {
            width: wCon(1000,1600);
            min-width: 1000px;
            margin-left: auto;
            margin-right: auto;
            @include media($mobileSize){
                width: wCon(600,640);
                max-width: 640px;
                min-width: 0;
            }
            &:last-of-type {
                .productsMachiner__teams {
                    @include flex-direction();
                    @include media($mobileSize){
                        @include column();
                        @include column-reverse();
                    }    
                    .productsMachiner__team {
                        &:first-of-type {
                            padding: wCon(20,1000);
                            background: -moz-linear-gradient(top right, rgba(255, 255, 255, 0.466), #FFF); 
                            background: -webkit-linear-gradient(top right, rgba(255, 255, 255, 0.466), #FFF); 
                            background: linear-gradient(to bottom left, rgba(255, 255, 255, 0.466), #FFF);
                            @include media($mobileSize){
                                padding: wCon(40,1000);
                            }
                        }
                    }    
                }
            }
            .productsMachiner__teams {
                @include flex();
                @include media($mobileSize){
                    @include column();
                    @include column-reverse();
                }
                .productsMachiner__team {
                    box-sizing: border-box;
                    width: wCon(500,1000);
                    @include media($mobileSize) {
                        width: 100%;
                    }
                    &:first-child {
                        padding: wCon(40,1000);
                        box-sizing: border-box;
                        // background-color: $white;
                        background: -moz-linear-gradient(top left, rgba(255, 255, 255, 0.466), #FFF); 
                        background: -webkit-linear-gradient(top left, rgba(255, 255, 255, 0.466), #FFF); 
                        background: linear-gradient(to bottom right, rgba(255, 255, 255, 0.466), #FFF);                         
                        .c-tit {
                        margin-bottom: 30px;
                            .c-titEn {
                                color: $black;
                            }
                        }
                        .productsMachiner__txt {
                            margin-bottom: 25px;
                            @include fontsize(14);
                            line-height: 1.8;
                            color: #333;
                        }
                        .productsMachiner__btn {
                            width: 180px;
                            @include media($mobileSize) {
                                margin: 0 auto;
                            }
                            a {
                                width: 100%;
                                display: block;
                                border: 1px solid $red;
                                color: $red;
                                padding: 13px 0;
                                box-sizing: border-box;
                                text-align: center;
                                @include fontsize(12);
                                &:hover {
                                    color: $white;
                                    &::after {
                                        background-color: $red;
                                    }                                
                                }
                            }
                        }
                    }
                    &:last-child {
                        img {
                            width: 100%;
                        }    
                    }
                }
            }
        }
    }
    // recruit
    .recruit {
        width: 100%;
        position: relative;
        img {
            width: 100%;
            @include media($mobileSize){
                object-fit: cover;
                height: 340px;
            }
        }
        .recruit__tit {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: 0 auto;
            width: wCon(810,1600);
            max-width: 810px;
            @include flex();
            @include content();
            @include column();
            text-align: center;
            @include media($mobileSize){
                width: wCon(600,640);
            }
            .c-tit {
                margin-bottom:  wCon(30,574);
            }
            .recruit__copy {
                color: $white;
                @include fontsize(14);
                line-height: 1.8;
                margin-bottom:  wCon(30,574);
            }
            .recruit__btn {
                width: 170px;
                margin: 0 auto;
                a {
                    width: 100%;
                    display: block;
                    color: $white;
                    @include fontsize(14);
                    border: 1px solid $white;
                    padding: 10px 0;
                    background-color: rgba(0,0,0,0.5);
                    &:hover {
                        &::after {
                            background-color: $black;
                        }                                
                    }
                }
            }
        }
    }
    // blog
    .blog {
        padding: wCon(80,1600) 0 wCon(105,1600);
        background-color: $black;
        @include media($mobileSize) {
            padding: wCon(80,640) 0 wCon(105,640);
        }
        .blog__inner {
            width: wCon(1000,1600);
            max-width: 1000px;
            margin: 0 auto;
            @include media($mobileSize) {
                width: wCon(600,640);
            }
            .c-tit {
                margin-bottom: 30px;
            }
            .blog__contents {
                @include flex();
                @include media($mobileSize) {
                    @include column();
                }
                .blog__lists {
                    width: wCon(323,1000);
                    margin-right: wCon(16,1000);
                    @include media($mobileSize) {
                        width: 100%;
                        margin-bottom: 20px;
                        margin-right: 0;
                    }    
                    &:last-child {
                        margin-right: 0;
                    }
                    .blog__img {
                        margin-bottom: 25px;
                        img {
                            width: 100%;
                        }
                        a {
                            &:hover {
                                @include opa(); 
                            }
                        }
                    }
                    .blog__ymd {
                        color: $grey;
                        @include fontsize(12);
                        margin-bottom: 10px;
                        @include media($mobileSize) {
                            @include fontsize(14);
                        }
                    }
                    .blog__tit {
                        @include fontsize(14);
                        color: $white;
                        @include media($mobileSize) {
                            @include fontsize(16);
                        }
                        a {
                            color: $white;
                            &:hover {
                                color: $red;
                                text-decoration-line: underline;
                                transition: .5s;
                            }
                        }
                    }
                }
            }    
        }
    }   
}



/*--  --*/
