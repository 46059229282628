/**************************************

function.scss

***************************************/

//----------------------------------------------//
// *Function                                    //
//----------------------------------------------//
/*------------------------------------------------
     wCon($width, $parentWidth : $columnWidth);

    幅の計算 (x / y * 100) をしてくれる関数

    property : wCon(%にしたいwidth, 直近の親要素のwidth※デフォルトはコンテンツ幅;


    [例1] コンテンツ幅(1024px)が直近の親要素の285pxの要素
    ●Sass   width : wCon(285);
    ●css    width : 27.83203%;

    [例2] 550pxが直近の親要素の380pxの要素
    ●Sass   width : wCon(380,550);
    ●css    width : 69.09091%;

		maginも同様

------------------------------------------------*/
// widthの横幅計算
@function wCon($width,$parentWidth:$columnWidth) {
    @return $width / $parentWidth * 100%;
}

// maginの横幅計算
@function mCon($width,$parentWidth:$columnWidth) {
    @return $width / $parentWidth * 100%;
}
