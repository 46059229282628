/**************************************

archive.scss

***************************************/
.archive {

    // mainVisual
    .mainVisual {

    }

    .contents {
        background-image: url(../img/blog/blog_pic2.jpg);
        background-position: 50% 60%;
        background-repeat: no-repeat;
        padding: wCon(50,1600) 0 wCon(205,1600);
        background-color: $black;
        @include media($mobileSize){
            padding: wCon(50,640) 0 wCon(205,640);
        }
        .contents__outer {
            width: 100%;
            &::before {
                content: "";
                height: 126px;
                margin-top: -126px;
                display: block;
                visibility: hidden;
                @include media($mobileSize){
                    height: 87px;
                    margin-top: -87px;
                }
            }
            .contents__inner {
                max-width: 1040px;
                width: wCon(1040,1600);
                margin: 0 auto;
                padding: wCon(50,1600) 0 wCon(60,1600);
                box-sizing: border-box;
                @include flex();
                @include justify();
                @include media($mobileSize){
                    width: wCon(600,640);
                    @include column();
                    // padding: wCon(50,640) wCon(20,640) wCon(60,640);
                }
                // article
                .contents__article {
                    width: wCon(814,1020);
                    box-sizing: border-box;
                    padding: wCon(30,1020) wCon(20,1020) wCon(70,1020);
                    background-color: $white;
                    @include media($mobileSize){
                        width: 100%;
                        margin-bottom: 30px;
                        padding: wCon(30,600) wCon(20,600) wCon(70,600);
                    }
                    .article__outer {
                        width: 100%;
                        position: relative;
                        transition: .5s;
                        &:hover {
                            .contents__articleDetail {
                                transition: .5s;
                                border-bottom: 1px solid #9D0106;
                                &::before {
                                    content: "";
                                    background-image: url(../img/blog/blog_pic7.svg;);
                                    right: 0;
                                }
                                .contents__articleDetailTit {
                                    color: #9D0106;
                                    transition: .5s;
                                }    
                            } 
                            .contents__articleThumbnail {
                                .contents__articleThumbnailInner {
                                    transition: .5s;
                                    background-position: 20% 50%;
                                }
                            }
                        }
                        article {
                            margin-bottom: 50px;
                            @include flex();
                            @include justify();
                            @include media($mobileSize){
                                @include column();
                            }    
                            .contents__archiveAnimation {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                z-index: 1;
                            }       
                            .contents__articleThumbnail {
                                width: wCon(248,814);
                                height: 153px;
                                position: relative;
                                @include media($mobileSize){
                                    width: 100%;
                                }            
                                .contents__articleThumbnailInner {
                                    position: relative;
                                    width: 100%;
                                    height: 100%;
                                    background-size: cover;
                                    background-position: 50% 50%;
                                    overflow: hidden;
                                    transition: .5s;             
                                }
                                // img {
                                //     width: 100%;
                                // }
                            }
                            .contents__articleDetail {
                                width: wCon(520,814);
                                box-sizing: border-box;
                                border-bottom: 1px solid #D0D0D0;
                                position: relative;
                                @include media($mobileSize){
                                    width: 100%;
                                    margin-top: 20px;
                                    padding-bottom: 20px;
                                }            
                                &::before {
                                    content: "";
                                    background-image: url(../img/blog/blog_pic6.svg;);
                                    background-repeat: no-repeat;
                                    width: 10px;
                                    height: 12px;
                                    position: absolute;
                                    right: 0;
                                    top: 40%;
                                }
                                .contents__articleCategory {
                                    position: relative;
                                    z-index: 1;
                                    .contents__catChildren {
                                        display: inline-block;
                                        margin-bottom: 20px;
                                        a {
                                            @include fontsize(14);
                                            font-weight: bold;
                                            color: #9D0106;
                                        }
                                    }
                                    .contents__articleYmd {
                                        color: #808080;
                                        @include fontsize(14);
                                        font-weight: bold;
                                    }    
                                }
                                .contents__articleDetailTit {
                                    @include fontsize(18);
                                    font-weight: bold;
                                    color: #000;
                                    line-height: 1.5;
                                }        
                            }
                        }    
                    }
                }
               // sidebar
                .contents__sidebar {
                    width: wCon(165,1020);
                    @include media($mobileSize){
                        width: 100%;
                    }
                    .contents__category {
                        background-color: $white;
                        &:first-of-type {
                            margin-bottom: 20px;
                        }
                        .contents__categoryName {
                                width: 100%;
                                display: block;
                                background-color: $red;
                                text-align: center;
                                font-weight: bold;
                                color: $white;
                                @include fontsize(14);
                                padding: 10px 0;
                                box-sizing: border-box;
                                @include media($mobileSize){
                                    @include fontsize(18);
                                }            
                            }
                        dd {
                            border-bottom: 1px solid #D0D0D0;
                            &:last-child {
                                border-bottom: none;
                            }
                            a {
                                width: 100%;
                                display: block;
                                font-weight: bold;
                                color: $black;
                                @include fontsize(14);
                                padding: 15px;
                                box-sizing: border-box;
                                transition: .5s;
                                &:hover {
                                    color: #B80000; 
                                    text-decoration-line: underline;
                                }
                                @include media($mobileSize){
                                    @include fontsize(16);
                                }            
                            }
                            .cat-item {
                                // border-top: 1px solid  #D0D0D0;
                                list-style-type: none;
                                &:first-child {
                                    border-top: none;
                                }
                                a {
                                }
                            }
                            .cat-item-1 {
                                border-bottom: 1px solid #D0D0D0;
                                padding-bottom: 15px;
                            }
                            .cat-item-2 {
                                padding-bottom: 15px;
                            }
                            .side-widget {
                                    li {
                                        a {
                                            padding: 15px 15px 0 15px; 
                                    }
                                .children {
                                            a {
                                                @include fontsize(12);
                                                color: #333;
                                                padding: 10px 0px 0px 25px;
                                                // margin-left: 15px;
                                                font-weight: normal;
                                                box-sizing: border-box;
                                                &:hover {
                                                    color: #B80000; 
                                                    text-decoration-line: underline;
                                                }                
                                                @include media($mobileSize){
                                                    @include fontsize(14);
                                                }                
                                            }
                                        }    
                                    }    
                                }
                            }
                        .contents__categoryYmd {
                            border: none;
                            .contents__categoryTit {
                                font-weight: bold;
                                color: $black;
                                @include fontsize(14);
                                padding: 10px 10px 0px 10px;
                                box-sizing: border-box;
                                @include media($mobileSize){
                                    @include fontsize(16);
                                }            
                            }
                            .contents__categoryMonth {
                                margin-left: 15px;
                                a {
                                    @include fontsize(12);
                                    color: #333;
                                    &:hover {
                                        color: #B80000; 
                                        text-decoration-line: underline;
                                    }    
                                    @include media($mobileSize){
                                        @include fontsize(14);
                                    }                
                                }
                            }
                        }
                    }
                }
                .contents__botomNav {
                    text-align: center;
                    span {
                        padding: 3px 5px;
                        border: 1px solid #000;
                        margin-right: 10px;
                        margin-left: 10px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                    a {
                        @include fontsize(14);
                        color: #333;
                        &:hover {
                            color: #B80000; 
                            text-decoration-line: underline;
                        }    
                    }
                }
            }
        }
    }

}



/*--  --*/
