/**************************************

business.scss

***************************************/

.business {
        // mainVisual
        .mainVisual {
        }
        // mainNav
        .mainNav {
            .c-mainNav--businessOuter {
                @include media($mobileSize) {
                    overflow: auto;
                    white-space: nowrap;
                    &::-webkit-scrollbar {
                        height: 5px;
                    }
                    &::-webkit-scrollbar-track {
                        background-color: #B2B2B2;
                    }
                    &::-webkit-scrollbar-thumb  {
                        background-color: #9C0106;
                    }
                }
                &:nth-of-type(1) {
                    border-bottom: 1px solid $white;
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    @include media($mobileSize_s){
                        border-bottom: none;
                        padding-bottom: 0;
                        margin-bottom: 0;
                    }
                }
            }
            .c-mainNavTeams--top {
                min-width: 900px;
                width: wCon(900,1600);
                display: table;
                margin: 0 auto;
                @include media($mobileSize){
                    width: wCon(600,640);
                }
                @include media($mobileSize_s){
                    min-width: 93.75%;
                }

                .c-mainNavTeam {
                    box-sizing: border-box;
                    line-height: 1.2;
                    display: table-cell;
                    vertical-align: middle;
                    &:nth-of-type(1) {
                        width: wCon(182,900);
                        a {
                            &::after {
                                right: 10%;
                            @include media($mobileSize_s){
                                right: 8%;
                                }
                            }
                        }
                        @include media($mobileSize_s){
                            width: 100%;
                        }
                    }
                    &:nth-of-type(2) {
                        width: wCon(220,900);
                        @include media($mobileSize_s){
                            width: 100%;
                        }                   
                    }
                    &:nth-of-type(3) {
                        width: wCon(276,900);
                        @include media($mobileSize_s){
                            width: 100%;
                        }
                    }
                    &:nth-of-type(4) {
                        width: wCon(124,900);
                        a {
                            &::after {
                                right: 14%;
                                @include media($mobileSize_s){
                                    right: 8%;
                                }    
                            }
                        }
                        @include media($mobileSize_s){
                            width: 100%;
                        }
                    }
                    @include media($mobileSize_s){
                        width: 100%;
                        display: block;
                        border: none;
                        &:nth-of-type(1) {
                            // border-right: 1px solid $white;
                            border-top: 1px solid $white;
                            border-bottom: 1px solid $white;
                        }
                        &:nth-of-type(2) {
                            // border-right: 1px solid $white;
                            border-bottom: 1px solid $white;
                        } 
                        &:nth-of-type(3) {
                            border-bottom: 1px solid $white;
                        }   
                    }
                    a {
                        width: 100%;
                        display: block;
                        padding: 10px 0;
                        text-align: center;
                        @include media($pcSize){
                            @include fontsize(12);
                        }
                        @include media($mobileSize){
                            @include fontsize(10);
                        }
                        @include media($mobileSize_s){
                            padding: 20px 0;
                            @include fontsize(12);
                        }
                        &::after {
                            // right: 4%;
                            // @include media($mobileSize_s){
                            //     right: 2%;
                            // }
                        }
                    }
                }    
            }
            .c-mainNavTeams--under {
                min-width: 750px;
                width: wCon(750,1600);
                display: table;
                margin: 0 auto;
                @include media($mobileSize){
                    width: wCon(600,640);
                }
                @include media($mobileSize_s){
                    min-width: 93.75%;
                }
                .c-mainNavTeam {
                    box-sizing: border-box;
                    line-height: 1.2;
                    display: table-cell;
                    vertical-align: middle;
                    @include media($mobileSize_s){
                        width: 100%;
                        display: block;
                        border: none;
                        &:nth-of-type(1) {
                            // border-right: 1px solid $white;
                            border-top: 1px solid $white;
                            border-bottom: 1px solid $white;
                        }
                        &:nth-of-type(2) {
                            // border-right: 1px solid $white;
                            border-bottom: 1px solid $white;
                        } 
                        &:nth-of-type(3) {
                            border-bottom: 1px solid $white;
                        }   
                    }
                    &:nth-of-type(1) {
                        // width: wCon(300,750);
                    }
                    &:nth-of-type(2) {
                        // width: wCon(200,750);                    
                    }
                    &:nth-of-type(3) {
                        // width: wCon(280,750);
                    }
                    a {
                        width: 100%;
                        display: block;
                        padding: 10px 0;
                        text-align: center;
                        @include media($pcSize){
                            @include fontsize(12);
                        }
                        @include media($mobileSize){
                            @include fontsize(10);
                        }
                        @include media($mobileSize_s){
                            padding: 20px 0;
                        }
                        // &::after {
                        //     right: 2%;
                        // }
                    }
                }
            }
        }
        // processing
        .processing {
            background-image: url(../img/business/business_pic2.jpg);
            background-position: 100% 15%;
            background-repeat: no-repeat;
            padding: wCon(50,1600) 0 wCon(205,1600);
            background-color: $black;
            background-size: cover;
            @include media($mobileSize){
                padding: wCon(50,640) 0 wCon(205,640);
            }
            .processing__outer {
                width: 100%;
                &::before {
                    content: "";
                    height: 126px;
                    margin-top: -126px;
                    display: block;
                    visibility: hidden;
                    @include media($mobileSize){
                        height: 87px;
                        margin-top: -87px;
                    }
                }    
                .processing__inner {
                    max-width: 1040px;
                    width: wCon(1040,1600);
                    margin: 0 auto;
                    background-color: $white;
                    padding: wCon(50,1600) wCon(20,1600) wCon(40,1600);
                    box-sizing: border-box;
                    @include media($mobileSize){
                        width: wCon(600,640);
                        padding: wCon(50,640) wCon(20,640) wCon(40,640);
                    }
                    .processing__teams {
                        @include flex();
                        @include justify();
                        @include media($mobileSize){
                            @include column-reverse();
                        }
                        .processing__team {
                            &:first-child {
                                width: wCon(505,1040);
                                @include fontsize(14);
                                line-height: 1.8;
                                color: #333;
                                @include media($mobileSize){
                                    width: 100%;
                                }
                            }
                            &:last-child {
                                width: wCon(464,1040);
                                margin-bottom: 10px;
                                img {
                                    width: 100%;
                                }
                                @include media($mobileSize){
                                    width: 100%;
                                }
                            }
                        }
                    }
                    .processing__lkmTeams {
                        @include flex();
                        @include justify();
                        @include wrap();
                        margin-bottom: 40px;
                        .processing__lkmTeam {
                            width: wCon(213,1040);
                            @include media($mobileSize){
                                width: wCon(265,600);
                                &:nth-of-type(1) {
                                    margin-bottom: 35px;
                                }
                                &:nth-of-type(2) {
                                    margin-bottom: 35px;
                                }
                            }
                            .processing__lkmImg {
                                text-align: center;
                                margin-bottom: 45px;
                            }
                            .processing__lkmTit {
                                color: $red;
                                font-weight: bold;
                                @include fontsize(18);
                                margin-bottom: 12px;
                                line-height: 1.2;
                                @include media($mobileSize){
                                    @include fontsize(16);
                                }
                            }
                            .processing__lkmTxt {
                                @include fontsize(12);
                                line-height: 1.8;
                            }
                        }
                    }
                    .processing__lkmBtn {
                        width: 220px;
                        margin: 0 auto;
                        .c-button {
                            width: 100%;
                            display: block;
                            border: 1px solid $red;
                            color: $red;
                            padding: 13px 0;
                            box-sizing: border-box;
                            text-align: center;
                            @include fontsize(14);
                            &:hover{
                                color: $white;
                                &::after {
                                    background-color: #A00106;
                                    top: 0;
                                    left: 0
                                }
                                &::before {
                                    background-image: url(../img/business/business_pic14_hover.svg);
                                    transition: .5s;
                                }      
                            }
                            &::before {
                                content: '';
                                position: absolute;
                                top: 30%;
                                left: 20%;
                                background-image: url(../img/business/business_pic14.svg);
                                background-repeat: no-repeat;
                                width: 18px;
                                height: 20px;
                                transition: .5s;
                            }
                            &::after {
                                top: -100%;
                                left: -100%;
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                z-index: -1;
                                display: block;
                                content: '';
                                -webkit-box-sizing: border-box;
                                -moz-box-sizing: border-box;
                                box-sizing: border-box;
                                -webkit-transition: all .3s;
                                transition: all .3s;
                            }
                        }
                    }
                }
            }
        }
    //微調整
    #processing__lkm {
        .u-mb-50 {
            @include media($mobileSize){
                margin-bottom: 50px;
            }
        }
    }
}



/*--  --*/
