/* misc - utility
--------------------------------------------------------- */

.u-ws-nowrap {
    white-space: nowrap;
}

.u-mx-img {
    max-width: 100%;
}

.u-tx-inside {
    text-indent: -1em;
    margin-left: 1em;
}