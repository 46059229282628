/**************************************

privacy.scss

***************************************/

.privacy {
    // mainVisual
    .mainVisual {
    }
    // persona
    .personal {
        background-image: url(../img/privacy/privacy_pic3.jpg);
        background-position: 0% 20%;
        background-repeat: no-repeat;
        padding: wCon(50,1600) 0 wCon(205,1600);
        background-color: $black;
        @include media($mobileSize){
            padding: wCon(50,640) 0 wCon(205,640);
        }
        .c-underPage {
            span {
                color: #B40106;
                text-decoration: underline;
            }
        }
        .personal__outer {
            width: 100%;
            &::before {
                content: "";
                height: 126px;
                margin-top: -126px;
                display: block;
                visibility: hidden;
                @include media($mobileSize){
                    height: 87px;
                    margin-top: -87px;
                }
            }
            .personal__inner {
            max-width: 1040px;
            width: wCon(1040,1600);
            margin: 0 auto;
            background-color: $white;
            padding: wCon(50,1600) wCon(20,1600) wCon(60,1600);
            box-sizing: border-box;
            @include media($mobileSize){
                    width: wCon(600,640);
                    padding: wCon(50,640) wCon(20,640) wCon(60,640);
                }
                .personal__detail {
                    .c-titLength {
                        margin-bottom: 30px;
                        @include media($mobileSize){
                            margin-bottom: 20px;
                        }
                    }
                    .personal__detailTxt {
                        @include fontsize(14);
                        line-height: 1.8;
                    }
                }
                .personal__ymd {
                    @include fontsize(14);
                    text-align: right;
                }
            }
        }
    }
}


/*--  --*/
