/**************************************

footer.scss

***************************************/
.footer{
    // footer__contact
	.footer__contact {
        width: 100%;
        background-color: $red;
        padding: wCon(60,1600) 0 wCon(50,1600);
        .footer__contactInner {
            width: wCon(1000,1600);
            max-width: 1000px;
            margin: 0 auto;
            @include media($mobileSize){
                width: wCon(600,640);
                padding: wCon(60,640) 0 wCon(50,640);
            }
            .c-tit {
                text-align: center;
                margin-bottom: 50px;
                .c-titJa {
                    color: $white;
                }
            }
            .footer__contactTxt {
                color: $white;
                text-align: center;
                margin-bottom: 12px;
                line-height: 1.5;
                @include fontsize(14);
                @include media($mobileSize){
                    @include fontsize(20);
                }    
            }
            .footer__contactBorder {
                color: $white;
                border-color: $white;
                margin-bottom: 30px;
                width: 100%;
            }
            .footer__contactTeams {
                // width: wCon(773,1600);
                width: 100%;
                max-width: 773px;
                margin: 0 auto;
                @include flex();
                @include justify();
                @include media($mobileSize){
                    width: 100%;
                    @include column();
                }
                .footer__contactTeam {
                    &:first-child {
                        width: wCon(391,773);
                        @include media($mobileSize){
                            width: 100%;
                            text-align: center;
                        }        
                        a {
                            width: 100%;
                            display: block;
                            &:hover {
                                @include opa();
                                transition: .5s;
                            }
                            img {
                                margin-bottom: 13px;
                                @include media($pcSize){
                                    margin-bottom: 8px;
                                }
                                @include media($mobileSize){
                                    margin-bottom: 20px;
                                }
                            }
                            .footer__contactTeamTxt {
                                color: $white;
                                @include fontsize(14);
                                margin-left: 20px;
                                // @include media($wideSize){
                                //     @include fontsize(12);
                                // }
                                // @include media($pcSize){
                                //     @include fontsize(10);
                                // }
                                @include media($mobileSize){
                                    @include fontsize(18);
                                    margin-left: 0;
                                    margin-bottom: 20px;
                                }
                            }    
                        }
                    }
                    &:last-child {
                        width: wCon(323,773);
                        @include media($mobileSize){
                            width: 100%;
                        }        
                        .footer__contactTeamBtn {
                            a {
                                width: 100%;
                                display: block;
                                background-color: $white;
                                color: $red;
                                text-align: center;
                                padding: wCon(20,323) 0;
                                box-sizing: border-box;
                                @include fontsize(14);
                                border: 1px solid $white;
                                @include media($wideSize){
                                    @include fontsize(10);
                                }
                                @include media($pcSize){
                                    @include fontsize(10);
                                }
                                @include media($mobileSize){
                                    @include fontsize(24);
                                    font-weight: bold;
                                }
                                &:hover {
                                    color: $white;
                                    &::after {
                                      background-color: $red;
                                    }                                
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    // footer__nav
    .footer__nav {
        padding: wCon(100,1600) 0 wCon(20,1600);
        background-color: $black;
        position: relative;
        @include media($mobileSize){
            padding: wCon(55,640) 0 wCon(20,640);
        }
        .footer__navInner {
            width: wCon(1040,1600);
            max-width: 1040px;
            margin-left: auto;
            margin-right: auto;
            @include flex();
            @include justify();
            @include media($wideSize){
                width: wCon(1280,1400);
            }
            .footer__navCompany {
                width: wCon(450,1040);
                color: $white;
                @include media($mobileSize){
                    width: 100%;
                }        
                .footer__navCompanyLogo {
                    margin-bottom: 32px;
                    @include media($mobileSize){
                        width: wCon(324,640);
                        margin: 0 auto 40px;
                        img {
                            width: 100%;
                        }    
                    }
                    @include media($mobileSize){
                        width: wCon(400,640);
                    }            
                }
                .footer__navCompanyName {
                    margin-bottom: 10px;
                    @include fontsize(16);
                    font-weight: bold;
                    letter-spacing: 0;
                    @include media($mobileSize){
                        text-align: center;
                        @include fontsize(18);
                    }            
                }
                .footer__navCompanyAccess {
                    @include fontsize(14);
                    line-height: 1.5;
                    letter-spacing: 0;
                    @include media($pcSize){
                        @include fontsize(12);
                    }
                    @include media($mobileSize){
                        text-align: center;
                        @include fontsize(16);
                    }            
                }
                .footer__navCompanyTel {
                    @include fontsize(14);
                    line-height: 1.5;
                    letter-spacing: 0;
                    @include media($pcSize){
                        @include fontsize(12);
                    }
                    @include media($mobileSize){
                        text-align: center;
                        @include fontsize(16);
                    } 
                    span {
                        &:first-of-type {
                            margin-right: 10px;
                            @include media($mobileSize_s){
                                margin-right: 0;
                            }
                        }               
                    }
                }
            }
            .footer__navName {
                width: wCon(616,1040);
                .footer__navNameTeams {
                    @include flex();
                    @include justify();    
                    .footer__navNameTeam {
                        .footer__navNamelist {
                            .footer__navNamelistName {
                                margin-bottom: 15px;
                                a {
                                    color: $white;
                                    font-weight: bold;
                                    @include fontsize(14);
                                    &:hover {
                                        color: $red;
                                        transition: 0.2s;
                                    }
                                    &::before {
                                        top: 1.2em;
                                        background-color: $red;
                                    }                                
                                }
                            }
                            .footer__navNamelistNameDetail {
                                line-height: 2;
                                a {
                                    color: $grey;
                                    @include fontsize(12);
                                    &:hover {
                                        @include opa();
                                    }   
                                }    
                            }
                        }
                    }
                }
            }
            .footer__copy {
                width: 100%;
                color: $darkGrey;
                @include flex();
                @include justify();
                @include media($mobileSize){
                    width: 100%;
                    @include column();
                }
                .footer__copyTxt {
                    @include fontsize(10);
                    line-height: 1.5;
                    @include media($mobileSize){
                        text-align: center;
                        @include fontsize(12);
                    }    
                    &:first-child {
                        margin-right: auto;
                        @include media($mobileSize){
                            margin-right: 0;
                            margin-bottom: 60px;
                        }
                    }
                    &:last-child {
                        @include media($mobileSize_s){
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
    .footer__pageTop {
        position: absolute;
        right: 5%;
        bottom: 20%;
        @include media($mobileSize){
            bottom: 5%;
            right: 2%;
            width: 62px;
        }
        @include media($mobileSize_s){
            width: 75px;
        }
    }
}
/* ---------------------------------------------------------

----------------------------------------------------------*/

/*--  --*/