/**************************************

company.scss

***************************************/

.company {
    // mainVisual
    .mainVisual {
    }
    // mainNav
    .mainNav {
        .c-mainNavTeams {
            max-width: 700px;
            width: wCon(700,1600);
            @include media($mobileSize){
                max-width: 600px;
                width: wCon(600,640);
                @include wrap();
            }    
            .c-mainNavTeam {
                width: wCon(225,700);
                box-sizing: border-box;
                @include media($mobileSize){
                    width: wCon(298,600);
                    &:nth-of-type(1) {
                        margin-bottom: 10px;
                    }
                    &:nth-of-type(2) {
                        margin-bottom: 10px;
                        border-right: 1px solid $white;
                    }
                }
                a {
                    padding: wCon(40,700) wCon(50,700);
                    box-sizing: border-box;
                }
            }
        }
    }
    // guide
    .guide {
        background-image: url(../img/company/company_pic2.jpg);
        background-position: 50% 50%;
        background-repeat: no-repeat;
        padding: wCon(50,1600) 0 wCon(205,1600);
        background-color: $black;
        @include media($mobileSize){
            padding: wCon(50,640) 0 wCon(205,640);
        }
        .guide__outer {
            width: 100%;
            &::before {
                content: "";
                height: 126px;
                margin-top: -126px;
                display: block;
                visibility: hidden;
                @include media($mobileSize){
                    height: 87px;
                    margin-top: -87px;
                }
            }
            .guide__inner {
                max-width: 1040px;
                width: wCon(1040,1600);
                margin: 0 auto;
                background-color: $white;
                padding: wCon(50,1600) wCon(20,1600) wCon(60,1600);
                box-sizing: border-box;
                @include media($mobileSize){
                    width: wCon(600,640);
                    padding: wCon(50,640) wCon(20,640) wCon(60,640);
                }
                // message
                .guide__president {
                    box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
                    margin-bottom: 50px;
                    @include media($mobileSize){
                        margin-bottom: 30px;
                    }            
                    img {
                        width: 100%;
                    }
                }
                .guide__drawing {
                    max-width: 660px;
                    width: wCon(660,1040);
                    margin-left: auto;
                    margin-right: auto; 
                    @include media($mobileSize){
                        width: wCon(500,600);
                    }
                    img {
                        width: 100%;
                    }
                }
                .guide__drawingTxt {
                    @include fontsize(14);
                    line-height: 1.8;
                    margin-bottom: 15px;
                    width: wCon(880,1040);
                    margin-left: auto;
                    margin-right: auto;
                }
                .guide__sign {
                    text-align: right;
                }
                // overview
                .guide__overviewTeams {
                    box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
                }
                .guide__overviewList {
                    display: table;
                    width: 100%;
                    box-sizing: border-box;
                    border-bottom: 1px solid #D2D2D2;
                    &:first-child {
                        border-top: 1px solid #D2D2D2;
                    }
                    .guide__overviewTit {
                        display: table-cell;
                        color: #333;
                        @include fontsize(18);
                        vertical-align: top;
                        line-height: 1.5;
                        padding: 30px 0 30px 40px;
                        box-sizing: border-box;
                        width: wCon(250,1040);
                        @include media($pcSize){
                            width: wCon(300,1040);
                        }
                        @include media($mobileSize){
                            width: 100%;
                            display: block;
                            padding: 10px 0 10px 10px;
                        }
                    }
                    .guide__overviewDetail {
                        display: table-cell;
                        color: #333;
                        @include fontsize(18);
                        line-height: 1.5;
                        text-align: left;
                        padding: 30px 0;
                        box-sizing: border-box;
                        span {
                            margin: 0 10px;
                        }
                        br {
                            display: none;
                        }
                        @include media($wideSize){
                            span {
                                display: none;
                            }
                            br {
                                display: block;
                            }
                        }
                        @include media($mobileSize){
                            width: 100%;
                            display: block;
                            padding: 0 0 10px 10px;
                        }
                    }
                }
                // history
                .guide__historyTable {
                    background: rgb(236,236,236);
                    background: linear-gradient(180deg, rgba(236,236,236,0) 0%, rgba(236,236,236,0.4990371148459384) 50%, rgba(236,236,236,1) 100%);
                    .guide__historys {
                        @include flex();
                        @include justify(); 
                        align-items: end;
                        @include media($mobileSize){
                            @include column();
                        }
                        .guide__historysYear {
                            // width: wCon(250,1040);
                            position: relative;
                            text-align: center;
                            img {
                                width: 100%;
                                height: 53px;
                            }
                            .guide__historysYearTxt {
                                color: $white;
                                font-weight: bold; 
                                letter-spacing: .2em; 
                                box-sizing: border-box;
                                position: absolute;
                                top: 0;
                                left: -20px;
                                right: 0;
                                bottom: 0;
                                margin: 0 auto;
                                @include content();
                                @include flex();
                                @include column();
                                @include fontsize(18);                      
                            }
                        }
                        .guide__historysDetail {
                            width: wCon(850,1040);
                            @include media($mobileSize){
                                width: 100%;
                            }          
                            .guide__historysDetailTeams {
                                @include flex();
                                @include space-evenly();  
                                align-items: baseline;
                                margin-top: 17px;   
                                .guide__historysMonth {
                                    width: wCon(80,762);
                                    background-color: $black;
                                    color: $white;
                                    @include fontsize(14);
                                    text-align: center;
                                    padding: 5px 0;
                                    box-sizing: border-box;
                                    @include media($mobileSize){
                                        width: wCon(100,640);
                                    }              
                                }
                                .guide__historysTxt {
                                    width: wCon(650,762);
                                    @include fontsize(14);
                                    line-height: 1.5;
                                    color: #333;
                                    @include media($mobileSize){
                                        width: wCon(500,640);
                                    }              
                                }    
                            }
                        }
                    }
                }
                // access
                .guide__accessTxt {
                    @include fontsize(14);
                    color: $white;
                    margin-bottom: 15px;
                }
                .guide__accessMap {
                    position: relative;
                    padding-bottom: 56.25%;
                    padding-top: 30px;
                    height: 0;
                    overflow: hidden;
                    iframe,object,embed {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                    .guide__accessBtn {
                        width: 140px;
                        position: absolute;
                        left: 65px;
                        bottom: 23px;
                        @include media($mobileSize_s){
                            display: none;
                        }
                        a {
                            @include fontsize(14);
                            color: $white;
                            background-color: $red;
                            padding: 15px 0;
                            box-sizing: border-box;
                            text-align: center;
                            width: 100%;
                            display: block;
                            border: 1px solid $red;
                            transition: .5s;
                            &:hover {
                                background-color: $white;
                                color: $red;
                            }
                        }
                    }
                }
            }
        }
    }
    // 微調整
    #history  {
        .guide__inner {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            .c-titLong {
                margin: 0 wCon(20,1600) 45px;
                box-sizing: border-box;
                @include media($mobileSize){
                    margin: 0 wCon(20,640) 45px;
                }
            }
            .guide__historyTable {
                padding: wCon(25,1600) wCon(20,1600);
                box-sizing: border-box;
                @include media($mobileSize){
                    padding: wCon(25,640) wCon(20,640);
                }
            }    
        }    
    }
    // 微調整
    #access {
        .guide__inner {
            padding: wCon(50,1600) wCon(20,1600) 0;
            background: transparent;
            .c-titLong {
                .c-titLongJa {
                    color: $white;
                }
            }
        }
    }   
}

/*--  --*/
