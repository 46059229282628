/**************************************

common.scss

***************************************/
/* ---------------------------------------------------------
大枠のレイアウト
----------------------------------------------------------*/
/*--  --*/
#mainContainer{
	color: $fontColorMain;
}

.outer{
	width: 100%;
	min-width: 1024px;
	letter-spacing: .1em;
	@include media($mobileSize){
		min-width: 100%;
	}
}


// .container{
// 	width: 960px;
// 	margin: 0 auto;
// 	position: relative;
// 	@include media($notepcSize){
// 		width: 87.5%;
// 	}
// 	@include media($mobileSize){
// 		width: 87.5%;
// 	}
// }
