/**************************************

single.scss

***************************************/

.single {
    // mainVisual
    .mainVisual {
        // .c-panList {
        //     &:nth-of-type(2) {
        //         &::after {
        //             content: ">";
        //             display: inline-block;
        //             color:  #C2C2C2;
        //             margin-right: 5px;
        //         }
        //     }
        // }
    }
    // contents
    .contents {
        background-image: url(../img/blog/blog_pic2.jpg);
        background-position: 50% 60%;
        background-repeat: no-repeat;
        padding: wCon(80,1600) 0 wCon(205,1600);
        background-color: $black;
        @include media($mobileSize){
            padding: wCon(80,640) 0 wCon(205,640);
        }
        .contents__outer {
            width: 100%;
            &::before {
                content: "";
                height: 126px;
                margin-top: -126px;
                display: block;
                visibility: hidden;
                @include media($mobileSize){
                    height: 87px;
                    margin-top: -87px;
                }
            }
            .contents__inner {
                max-width: 1020px;
                // width: wCon(1020,1600);
                width: 64.75%;
                margin: 0 auto;
                box-sizing: border-box;
                @include flex();
                @include justify();
                @include media($mobileSize){
                    width: wCon(600,640);
                    @include column();
                }
                // article
                .contents__article {
                    width: wCon(814,1020);
                    box-sizing: border-box;
                    padding: wCon(30,1020) wCon(20,1020) wCon(70,1020);
                    background-color: $white;
                    @include media($mobileSize){
                        width: 100%;
                        margin-bottom: 30px;
                    }
                    article {
                        margin-bottom: 50px;
                    }
                    .contents__ymdCategory {
                        @include flex();
                        align-items: baseline;
                        .post-categories {
                            margin-right: 10px;
                            @include flex();
                            li {
                                margin-right: 10px;
                                a {
                                    @include fontsize(14);
                                    color: #A00106;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                    .contents__articleYmd {
                        @include fontsize(14);
                        font-weight: bold;
                        color: #808080;
                        margin-bottom: 25px;
                    }
                    .contents__articleTit {
                        @include fontsize(22);
                        font-weight: bold;
                        color: #000;
                        padding-bottom: 30px;
                        border-bottom: 1px solid #D0D0D0;
                        margin-bottom: 50px;
                    }
                    .contents__articleThumbnail {
                        margin-bottom: 25px;
                    }
                    .contents__articleTxt {
                        p {
                            @include fontsize(14);
                            line-height: 1.7;
                        }
                        h1 {
                            background-color: #9D0106;
                            margin-bottom: 20px;
                            box-sizing: border-box;
                            padding: 15px;
                            color: $white;
                            @include fontsize(22);
                            font-weight: bold;
                        }
                        h2 {
                            @include fontsize(18);
                            font-weight: bold;
                            position: relative;
                            padding-left: 15px;
                            line-height: 1.2;
                            margin-bottom: 20px;
                            &::before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                right: 0;
                                width: 7px;
                                height: 20px;
                                background-color: #B80000;
                                line-height: 1.2;
                            }                        
                        }
                        h3 {
                            color: $red;
                            @include fontsize(16);
                            font-weight: bold;
                            margin-bottom: 20px;
                        }
                        h4,h5,h6 {
                            color: $black;
                            @include fontsize(16);
                            font-weight: bold;
                            margin-bottom: 20px;
                        }
                        blockquote {
                            background-color: #E6E6E6;
                            box-sizing: border-box;
                            padding: wCon(30,1020);
                        }
                    }
                    .contents__snsOuter {
                        padding: 25px 0;
                        box-sizing: border-box;
                        border-top: 1px solid #D0D0D0;
                        border-bottom: 1px solid #D0D0D0;
                        .contents__snsTeams {
                            @include flex();
                            @include justify(); 
                            width: wCon(600,1020);
                            margin: 0 auto;
                            text-align: center;
                            @include media($mobileSize){
                                width: wCon(300,600);
                                @include column();
                                @include content();
                            }        
                            .contents__snsTeam {
                                width: wCon(190,600);
                                @include media($mobileSize){
                                    width: 100%;
                                    @include column();
                                    margin-bottom: 10px;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }            
                                a {
                                    width: 100%;
                                    display: block;
                                    img {
                                        width: 100%;
                                    }
                                }
                            }
                        }    
                    }
                    .contents__botomNav {
                        .contents__botomNavInner {
                            @include flex();
                            @include content();
                            a {
                                @include fontsize(14);
                                color: $black; 
                            }
                            .contents__botomNavtop {
                                margin: 0 35px;
                                a {
                                    border: 1px solid $black;
                                    padding: 7px 20px;
                                    box-sizing: border-box;
                                    &:hover {
                                        color: $white;
                                        background-color: $black;
                                        transition: .5s;
                                      } 
                                }
                            }
                            .contents__botomNavLeft,.contents__botomNavright {
                                a {
                                    position: relative;
                                    display: inline-block;
                                    text-decoration-line: none;
                                    &::before {
                                        position: absolute;
                                        top: 1.2em;
                                        left: 0;
                                        content: "";
                                        display: inline-block;
                                        width: 0;
                                        height: 1px;
                                        background-color: $black;
                                        transition: 0.2s;
                                    }
                                    &:hover {
                                        &::before {
                                            width: 100%;
                                        }
                                    }                                
                                }
                            }
                        }
                    }
                }
                // sidebar
               .contents__sidebar {
                width: wCon(165,1020);
                @include media($mobileSize){
                    width: 100%;
                }
                .contents__category {
                    background-color: $white;
                    &:first-of-type {
                        margin-bottom: 20px;
                    }
                    .contents__categoryName {
                            width: 100%;
                            display: block;
                            background-color: $red;
                            text-align: center;
                            font-weight: bold;
                            color: $white;
                            @include fontsize(14);
                            padding: 10px 0;
                            box-sizing: border-box;
                            @include media($mobileSize){
                                @include fontsize(18);
                            }            
                        }
                    dd {
                        border-bottom: 1px solid #D0D0D0;
                        &:last-child {
                            border-bottom: none;
                        }
                        a {
                            width: 100%;
                            display: block;
                            font-weight: bold;
                            color: $black;
                            @include fontsize(14);
                            padding: 15px;
                            box-sizing: border-box;
                            transition: .5s;
                            &:hover {
                                color: #B80000; 
                                text-decoration-line: underline;
                            }
                            @include media($mobileSize){
                                @include fontsize(16);
                            }            
                        }
                        .cat-item {
                            // border-top: 1px solid  #D0D0D0;
                            list-style-type: none;
                            &:first-child {
                                border-top: none;
                            }
                            a {
                            }
                        }
                        .cat-item-1 {
                            border-bottom: 1px solid #D0D0D0;
                            padding-bottom: 15px;
                        }
                        .cat-item-2 {
                            padding-bottom: 15px;
                        }
                        .side-widget {
                                li {
                                    a {
                                        padding: 15px 15px 0 15px; 
                                }
                            .children {
                                        a {
                                            @include fontsize(12);
                                            color: #333;
                                            padding: 10px 0px 0px 25px;
                                            // margin-left: 15px;
                                            font-weight: normal;
                                            box-sizing: border-box;
                                            &:hover {
                                                color: #B80000; 
                                                text-decoration-line: underline;
                                            }                
                                            @include media($mobileSize){
                                                @include fontsize(14);
                                            }                
                                        }
                                    }    
                                }    
                            }
                        }
                    .contents__categoryYmd {
                        border: none;
                        .contents__categoryTit {
                            font-weight: bold;
                            color: $black;
                            @include fontsize(14);
                            padding: 10px 10px 0px 15px;
                            box-sizing: border-box;
                            @include media($mobileSize){
                                @include fontsize(16);
                            }            
                        }
                        .contents__categoryMonth {
                            margin-left: 15px;
                            a {
                                @include fontsize(12);
                                color: #333;
                                &:hover {
                                    color: #B80000; 
                                    text-decoration-line: underline;
                                }    
                                @include media($mobileSize){
                                    @include fontsize(14);
                                }                
                            }
                        }
                    }
                }
            }
        }
        }
    }

}



/*--  --*/
