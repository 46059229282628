/* margin - utility
--------------------------------------------------------- */

/* auto margin */

.u-m-c {
    margin-right: auto !important;
    margin-left: auto !important;
}

/* Bottom margin */
.u-mb-10 {
    margin-bottom: 10px;
    @include media($mobileSize){
        margin-bottom: 5px; 
    }
}
.u-mb-20 {
    margin-bottom: 20px;
    @include media($mobileSize){
        margin-bottom: 10px;    
    }
}
.u-mb-30 {
    margin-bottom: 30px;
    @include media($mobileSize){
        margin-bottom: 5px; 
    }
}
.u-mb-40 {
    margin-bottom: 40px;
    @include media($mobileSize){
        margin-bottom: 20px;    
    }
}
.u-mb-50 {
    margin-bottom: 50px;
    @include media($mobileSize){
        margin-bottom: 25px;    
    }
}
.u-mb-60 {
    margin-bottom: 60px;
    @include media($mobileSize){
        margin-bottom: 30px;    
    }
}
.u-mb-70 {
    margin-bottom: 70px;
    @include media($mobileSize){
        margin-bottom: 35px;    
    }
}
.u-mb-80 {
    margin-bottom: 80px;
    @include media($mobileSize){
        margin-bottom: 40px;    
    }
}
.u-mb-90 {
    margin-bottom: 90px;
    @include media($mobileSize){
        margin-bottom: 45px;    
    }
}
.u-mb-100 {
    margin-bottom: 100px;
    @include media($mobileSize){
        margin-bottom: 50px;    
    }
}
.u-mb-110 {
    margin-bottom: 110px;
    @include media($mobileSize){
        margin-bottom: 55px;    
    }
}

.u-mb-120 {
    margin-bottom: 120px;
    @include media($mobileSize){
        margin-bottom: 60px;    
    }
}
.u-mb-130 {
    margin-bottom: 130px;
    @include media($mobileSize){
        margin-bottom: 65px;    
    }
}


/* Top margin */
@for $value from 1 to 10 {
    .u-mt-#{$value * $base-size - $base-size} {
        margin-top: $base-size * $value - $base-size + px !important;
    }
}

/* Right margin */
@for $value from 1 to 10 {
    .u-mr-#{$value * $base-size - $base-size} {
        margin-right: $base-size * $value - $base-size + px !important;
    }
}

/* Bottom margin */
@for $value from 1 to 10 {
    .u-mb-#{$value * $base-size - $base-size} {
        margin-bottom: $base-size * $value - $base-size + px !important;
    }
}

/* Left margin */
@for $value from 1 to 10 {
    .u-ml-#{$value * $base-size - $base-size} {
        margin-left: $base-size * $value - $base-size + px !important;
    }
}