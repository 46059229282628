/**************************************

header.scss

***************************************/
.header{
    // PC時
    // background-color: black;
    position: fixed;
    z-index: 5;
    width: 100%;
    transition: .5s;
    .headerPc {
        box-sizing: border-box;
        .headerPc__inner {
            @include flex();
            @include justify();
            width: wCon(1320,1600);
            margin-left: auto;
            align-items: center;
            .headerPc__logo {
                width: 268px;
                a {
                    width: 100%;
                    box-sizing: border-box;
                    display: block;
                    &:hover {
                        @include opa();
                        transition: .5s;
                    }
                    // img {
                    //     width: 100%;
                    // }
                }
            }
            .headerPc__nav {
                @include flex();
                width: wCon(723,1320);
                .headerPc__navTeams {
                    @include flex();
                    @include justify();
                    width: wCon(446,723);
                    margin-right: auto;
                    align-items: center;
                    .headerPc__navTeam {
                        a {
                            width: 100%;
                            display: block;
                            color: $white;
                            @include fontsize(14);
                            text-align: center;
                            transition: .5s;
                            &:hover {
                                color: $red; 
                            }
                            @include media($pcSize){
                                @include fontsize(12);
                            }
                        }
                    }
                }
                .headerPc__contactBtn {
                    width: wCon(235,723);
                    overflow: hidden;
                    position: relative;
                    a {
                        width: 100%;
                        display: block;
                        &:hover {
                            @include opa();
                            transition: .5s;
                        }
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    // スマホ時
    .headerSp {
        width: 100%;
        position: relative;
        background-color: $black;
        .headerSp__inner {
            width: wCon(600,640);
            margin: 0 auto;
            max-width: 600px;
            @include flex();
            @include justify();
            padding-top: 20px;
            .headerSp__logo {
                width: 165px;
                a {
                    width: 100%;
                    display: block;
                    &:hover {
                        @include opa();
                        transition: .5s;
                    }
                    img {
                        width: 100%;
                    }    
                }
            }
            .headerSp__hamburger {
                display: inline-block;
                transition: all .4s;
                box-sizing: border-box;
                position: relative;
                width: 53px;
                height: 62px;
                z-index: 20;        
                span {
                    display: inline-block;
                    transition: all .4s;
                    box-sizing: border-box;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 4px;
                    background-color: #fff;
                    &:nth-of-type(1) {
                        top: 0;
                    }
                    &:nth-of-type(2) {
                        top: 14px;
                    }
                    &:nth-of-type(3) {
                        bottom: 30px;
                        transition: none;
                    }                   
                }
                .headerSp__hamburgerOpen,.headerSp__hamburgerClose {
                    color: $white;
                    position: absolute;
                    bottom: 10px;
                    @include fontsize(16);
                    letter-spacing: .25em;
                }
                .headerSp__hamburgerClose { 
                    display: none;
                    letter-spacing: .1em;
                }         
            }
            .headerSp__hamburgerActive {
                span {
                    &:nth-of-type(1) {
                        -webkit-transform: translateY(13px) rotate(-45deg);
                        transform: translateY(13px) rotate(-45deg);                      
                    }
                    &:nth-of-type(2) {
                        opacity: 0;                      
                    }
                    &:nth-of-type(3) {
                        -webkit-transform: translateY(-15px) rotate(45deg);
                        transform: translateY(-15px) rotate(45deg);
                    }    
                }
                .headerSp__hamburgerClose {
                    display: block;
                }
                .headerSp__hamburgerOpen {
                    display: none;
                }
            }
        }
        .headerSp__nav {
            display: none;
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: $black;
            z-index: -1;
            overflow-y: auto;
            top: 0;
            left: 0;
            .headerSp__navInner {
                width: wCon(600,640);
                margin: 0 auto;
                max-width: 600px;
                padding-top: 150px;
                padding-bottom: 55px;
                .headerSp__navlists {
                    border-bottom: 1px solid #666;
                    &:first-of-type {
                        border-top: 1px solid #666;
                    }
                    &:last-of-type {
                        margin-bottom: 60px;
                    }
                    .headerSp__navlistTit {
                        @include fontsize(24);
                        color: $white;
                        position: relative;
                        letter-spacing: .2em;
                        a {
                            width: 100%;
                            display: block;
                            box-sizing: border-box;
                            padding: 30px 0;
                            color: $white;
                        }
                        &::after {
                            content: "";
                            background-image: url(../img/common/arrow.png);
                            background-repeat: no-repeat;
                            width: 28px;
                            height: 18px;
                            top: 24px;
                            right: 10px;
                            position: absolute;
                            -webkit-transform: rotate(-90deg);
                            transform: rotate(-90deg);
                        }
                    }
                    .headerSp__navlistTit--accordion {
                        padding: 30px 0;
                        &::after {
                            top: 35px;
                            -webkit-transform: rotate(0deg);
                            transform: rotate(0deg);
                            transition: .5s;                     
                        }
                    }
                    .headerSp__navlistTit--accordionActive {
                        &::after {
                            -webkit-transform: rotate(-60deg);
                            transform: rotate(-60deg);
                            transition: .5s;
                        }
                    }
                    .headerSp__navlistDetail {
                        display: none;
                        margin-bottom: 25px;
                        margin-left: 20px;
                        a {
                            width: 100%;
                            display: block;
                            color: $grey;
                            @include fontsize(20);
                            margin-bottom: 30px;
                            &:last-child {
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
                .headerSp__contactBtn{
                    a {
                        width: 100%;
                        display: block;
                        @include fontsize(24);
                        background-color: $red;
                        text-align: center;
                        padding: 30px 0;
                        color: $white;
                    }
                }  
            }
        }
    }
}
    // スクロール時に背景を変化させる
    .header__scroll {
        background-color: $black;
        transition: .5s;
    }    


/* ---------------------------------------------------------

----------------------------------------------------------*/
/*--  --*/
